import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import mainImg from "../../images/services/coach-hire-services-contract-work.jpg"
import NewsStrip from "../../components/news-strip"
import arrowSvg from "../../images/arrow-circle-right-solid.svg"

class ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Contract coach hire" description="Contract coach hire by Tetley's Coaches - Leeds' oldest bus and coach company established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Contract Work</h1>
            </div>

            <div className="news-article-body">

              <p>Tetley's Coaches have years of experience in transporting employees to and from their place of work. However many passengers, whatever the time and location and however long the contract, we would welcome your enquiry and provide a very competitive quote.</p>
              <p>
                <img className="service-img" src={mainImg} alt="Contract coach hire" />
              </p>
              <p>We have the coach to match your requirements, your expectations and your budget. Our 16, 33, 49, 57 and 70 seat coaches are fully seat-belted. Our executive series coaches have the added benefit of on-board WC, Wifi, radio with USB connection and air-conditioning.</p>
              <p>A large proportion of our work is repeat business which is no surprise. It's a testament to our high level of customer care, our experience, our vehicles and our staff.</p>
              <p>If you're looking for quality and experience, we're the coach hire company to call. Established in 1949, Tetley's Coaches have provided comfortable, safe and reliable coach transport for over 75 Years.</p>
              <p className="text-center">
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
